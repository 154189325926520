<template>
  <SfModal
    class="sf-modal variant-selection-modal"
    :visible.value="showWishlistModal || showAtcModal"
    cross
    overlay
    @close="close"
  >
    <template v-if="product">
      <WishlistVariant v-if="showWishlistModal" />
      <CartVariant v-else-if="showAtcModal" />
    </template>
    <SfLoader v-else></SfLoader>
  </SfModal>
</template>

<script lang="ts" setup>
const { showWishlistModal } = storeToRefs(useWishlistStore())
const { products, activeProductSku } = storeToRefs(useProductStore())
const { showAtcModal } = storeToRefs(useCartStore())

const product = computed(() => {
  return products.value[activeProductSku.value]
})

watch(
  () => showWishlistModal.value,
  () => {
    showWishlistModal.value ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll')
  },
)

const close = () => {
  showWishlistModal.value = false
  showAtcModal.value = false
  activeProductSku.value = null
}
</script>

<style lang="scss" scoped>
.variant-selection-modal {
  position: relative;
  z-index: 10;
  .sf-loader {
    min-height: var(--spacer-2xl);

    &__overlay {
      background: none;
    }
  }

  .sf-modal__container {
    --modal-index: 21;
  }

  .sf-modal__overlay {
    --overlay-z-index: 20;
  }

  .sf-button--add-to-wishlist {
    position: relative;
    overflow: hidden;
    margin-top: auto;
    height: auto;
    padding: 16px 32px;
    white-space: normal;
    line-height: 130%;
  }
}
</style>
