<template>
  <div id="cart">
    <Sidebar :title="t('My Cart')" :visible="isCartSidebarOpen" position="right" @close="toggleCartSidebar">
      <SfNotification
        :visible="!!notificationMessage"
        :message="notificationMessage"
        type="success"
        @click:close="() => (notificationMessage = null)"
      />

      <SfLoader class="my-cart-wrapper" :loading="!cartLoaded">
        <div v-if="cartProductAmount" key="my-cart" class="my-cart">
          <CartGiftWrapping />
          <div v-if="productsWithPrices" class="collected-product-list">
            <CartItem v-for="(product, index) in productsWithPrices.slice(0, 2)" :key="index + product.uid" :product />
            <CartShinyCombination v-if="productsWithPrices.length > 1" key="shiny" />
            <CartItem
              v-for="(product, index) in productsWithPrices.slice(2, productsWithPrices.length)"
              :key="`p-${index}${product.uid}`"
              :product
            />
          </div>
        </div>
        <div v-else key="empty-cart" class="empty-cart">
          <CartAlternativeProducts
            v-if="exponeaRecommendations?.length"
            :items="exponeaRecommendations"
            :title="t('We believe you might like these items')"
          />
          <div v-else class="empty-cart__banner">
            <SfHeading class="empty-cart__heading" :title="t('Your cart is empty')" :level="6" />
            <EmptyCartImage class="empty-cart__image" />
            <SfHeading
              class="empty-cart__description"
              :description="t('Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.')"
            />
          </div>
        </div>
        <!-- </transition> -->
      </SfLoader>
      <template #content-bottom>
        <div v-if="cartProductAmount">
          <div class="my-cart__totals">
            <SfProperty class="sf-property--full-width my-cart__shipping-price" :name="t('Shipping')">
              <template #value>
                <span v-if="isFreeShipping" class="my-cart__shipping-price--free">
                  {{ t('Free shipping') }}
                </span>
                <SfPrice v-else-if="shippingCost > 0" :regular="$formatCurrency(shippingCost)" />
                <span v-else class="sf-property__value">
                  {{
                    t('Order {price} more for free shipping', {
                      price: $formatCurrency(MINIMUM_TOTAL_FOR_FREE_SHIPPING - subtotal),
                    })
                  }}
                </span>
              </template>
            </SfProperty>
            <SfProperty v-if="shippingCost > 0" class="sf-property--full-width my-cart__shipping-price" :name="t('')">
              <template #value>
                <span class="sf-property__value">
                  {{
                    t('Order {price} more for free shipping', {
                      price: $formatCurrency(MINIMUM_TOTAL_FOR_FREE_SHIPPING - subtotal),
                    })
                  }}
                </span>
              </template>
            </SfProperty>
            <SfProperty class="sf-property--full-width my-cart__subtotal-price" :name="t('Subtotal')">
              <template #value>
                <SfPrice :regular="$formatCurrency(priceTotalCalculated)" special="" />
              </template>
            </SfProperty>
            <SfProperty
              v-if="totalProductsDiscount > 0"
              class="sf-property--full-width my-cart__discount-price"
              :name="t('Discount')"
            >
              <template #value>
                <SfPrice :regular="$formatCurrency(totalProductsDiscount)" special="" />
              </template>
            </SfProperty>
            <template v-if="discounts">
              <SfProperty
                v-for="(discount, index) in discounts"
                :key="index"
                class="sf-property--full-width my-cart__discount-price"
                :name="t(discount.code || 'Discount')"
              >
                <template #value>
                  <SfPrice :regular="$formatCurrency(discount.value * -1)" special="" />
                </template>
              </SfProperty>
            </template>
            <SfProperty class="sf-property--full-width my-cart__total-price" :name="t('Total')">
              <template #value>
                <SfPrice :regular="$formatCurrency(total)" />
              </template>
            </SfProperty>
          </div>
          <CartCouponCode />

          <NuxtLink
            class="sf-button sf-button--full-width sf-button--black"
            :to="localePath('/checkout/')"
            :disabled="checkoutDisabled"
            @click="toggleCartSidebar"
          >
            {{ t('Go to checkout') }}
          </NuxtLink>

          <div class="go-to-cart-wrap text-center">
            <NuxtLink
              class="sf-button sf-button--text go-to-cart-btn"
              :class="{ 'is-disabled--button': checkoutDisabled }"
              :to="localePath(`/checkout/cart`)"
              :aria-disabled="checkoutDisabled"
              @click.native="toggleCartSidebar"
            >
              {{ t('Extended overview') }}
            </NuxtLink>
          </div>
        </div>
        <div v-else>
          <SfButton class="sf-button--full-width sf-button--hollow" @click="toggleCartSidebar">
            {{ t('Continue shopping') }}
          </SfButton>
        </div>
        <!-- </transition> -->
      </template>
    </Sidebar>
  </div>
</template>

<script setup lang="ts">
import EmptyCartImage from '~/assets/icons/empty-cart.svg'
import useExponeaConstants from '~/utils/constants/exponea'
import cartGetters from '~/utils/getters/magentoCartGetters'
import type { CartItemInterface, Discount, Maybe } from '@vue-storefront/magento-types'

const localePath = useLocalePath()
const uiState = useUiState()
const { isCartSidebarOpen } = storeToRefs(uiState)
const { toggleCartSidebar } = uiState
const cartStore = useCartStore()
const { cart, cartItems } = storeToRefs(cartStore)
const { load: loadCart, loading } = useCart()
const { t } = useI18n()
const notificationMessage = useState('notificationMessage')

const total = computed(() => cart.value?.prices?.grand_total?.value || 0)
const subtotal = computed(() => cart.value?.prices?.subtotal_including_tax?.value || 0)

const { MINIMUM_TOTAL_FOR_FREE_SHIPPING } = useConstants()

const discounts = computed(
  () =>
    cart.value?.prices?.discounts?.map((d: Maybe<Discount>) => ({
      id: d?.label,
      name: d?.label,
      description: '',
      value: d?.amount.value,
      code: d?.label,
    })) || [],
)

const totalProductsDiscount = computed(() => {
  const totalRegularPrice =
    cart.value?.items?.reduce(
      (acc, item) => acc + item!.product.price_range.maximum_price!.regular_price.value! * item!.quantity,
      0,
    ) || 0
  const totalFinalPrice =
    cart.value?.items?.reduce(
      (acc, item) => acc + item!.product.price_range.maximum_price!.final_price.value! * item!.quantity,
      0,
    ) || 0
  return Math.round((totalRegularPrice - totalFinalPrice) * 100) / 100
})

const cartProductAmount = computed(() => cartGetters.getTotalItems(cart.value))
const shippingCost = computed(() => cartGetters.getShippingPrice(cart.value))
const cartLoaded = ref(false)
const checkoutDisabled = computed(() => {
  const out_of_stock_items = cart.value?.items?.filter(
    (item: any) => item.configured_variant?.stock_status === 'OUT_OF_STOCK',
  )
  return out_of_stock_items?.length || loading.value || null
})

const loadCartOrAlternativeProducts = async () => {
  await loadCart()
  if (!cart.value?.items?.length) {
    await exponeaStore.getRecommendation({ id: EMPTY_CART_RECOMMENDATIONS_ID, fillWithRandom: true, size: 6 })
  }
  cartLoaded.value = true
}

onMounted(() => {
  if (!cartLoaded.value) loadCartOrAlternativeProducts()
})

// Exponea recommended products
const exponeaStore = useExponeaStore()
const { EMPTY_CART_RECOMMENDATIONS_ID } = useExponeaConstants()
const exponeaRecommendations = computed(
  () => exponeaStore.getExponeaRecommendationById(EMPTY_CART_RECOMMENDATIONS_ID) || [],
)

const productsWithPrices = computed(() =>
  cartItems.value
    ?.map((cartItem: CartItemInterface) => {
      const minimumPrice = parseFloat(
        (cartItem.product.price_range.minimum_price.regular_price?.value! * cartItem.quantity).toFixed(2),
      )
      const totalIncludingTax = cartItem.prices!.row_total_including_tax.value!

      return {
        ...cartItem,
        regularPrice: (totalIncludingTax < minimumPrice && minimumPrice) || totalIncludingTax,
        specialPrice: (totalIncludingTax < minimumPrice && totalIncludingTax) || null,
      }
    })
    .reverse(),
)

const priceTotalCalculated = computed(() => productsWithPrices.value?.reduce((acc, item) => acc + item.regularPrice, 0))
const isFreeShipping = computed(
  () => cart.value && subtotal.value >= MINIMUM_TOTAL_FOR_FREE_SHIPPING && shippingCost.value === 0,
)
</script>

<style lang="scss">
#cart {
  --sidebar-z-index: 10;
  --overlay-z-index: 10;

  .promo-code {
    margin-bottom: var(--spacer-xs);

    @include for-desktop {
      margin-bottom: var(--spacer-sm);
    }
  }

  .cart-empty {
    &__subtitle {
      @include for-mobile {
        --property-value-font-size: var(--font-size--xs);
        --property-value-font-line-height: var(--spacer-18);
      }
    }
  }

  .cart-summary {
    --property-name-margin: 0 var(--spacer-sm) 0 0;
    --property-name-font-size: var(--font-size-10);
    --property-name-font-line-height: 1.8;
    --property-value-font-size: var(--font-size-10);
    --property-value-font-line-height: 1.8;
    --property-name-color: var(--black-color);
    --property-value-color: var(--black-color);
    --property-name-font-family: var(--font-family--primary);
    --property-value-font-family: var(--font-family--primary);
    --property-name-font-weight: var(--font-weight--semibold);
    --property-value-font-weight: var(--font-weight--semibold);

    @include for-desktop {
      --property-name-font-size: var(--font-size--sm);
      --property-name-font-line-height: 1.4286;
      --property-value-font-size: var(--font-size--sm);
      --property-value-font-line-height: 1.4286;
      margin-top: var(--spacer-sm);
    }
  }

  .sf-sidebar__top {
    .sf-heading__title {
      margin-bottom: 0;
      padding-bottom: var(--spacer-xs);
    }
  }

  .sf-sidebar__bottom {
    background-color: var(--white-color);
    bottom: 0;
    top: auto;
    z-index: 1;
    padding-top: var(--spacer-xs);

    @include for-desktop {
      padding-top: var(--spacer-sm);
    }

    .sf-button {
      margin-bottom: var(--spacer-xs);

      @include for-desktop {
        margin-bottom: var(--spacer-sm);
      }

      &--text {
        display: inline-block;

        @include for-mobile {
          --button-font-size: var(--font-size--sm);
          --button-font-line-height: var(--spacer-18);
        }
      }
    }
  }

  .go-to-cart-wrap {
    padding-top: var(--spacer-xs);

    @include for-desktop {
      padding-top: var(--spacer-sm);
      padding-bottom: var(--spacer-xs);
    }
  }

  .sf-notification {
    margin-bottom: var(--spacer-sm);
  }
}

.my-cart-wrapper {
  flex: 1;
  display: flex;
  height: auto;
  flex-direction: column;
}

.my-cart {
  flex: 1;
  display: flex;
  flex-direction: column;
  --property-name-content: unset;

  &__total-items {
    margin: 0;
  }

  &__shipping-price {
    .sf-property__value {
      font-size: var(--font-size--xs);
      text-align: right;

      @include for-desktop {
        font-size: var(--font-size--sm);
      }
    }

    &--free {
      font-size: var(--font-size--xs);
      color: var(--green-color);

      @include for-desktop {
        font-size: var(--font-size--sm);
      }
    }
  }

  &__discount-price {
    --price-regular-color: var(--red-color);

    .sf-property__name {
      --property-name-color: var(--red-color);
    }
  }

  &__totals {
    .sf-property {
      --property-name-content: unset;
      margin: 0 0 var(--spacer-2xs);
      --property-name-font-size: var(--font-size--xs);

      @include for-desktop {
        margin: 0 0 var(--spacer-xs);
        --property-name-font-size: var(--font-size--sm);
      }

      &__name {
        &:after {
          display: none;
        }
      }
    }
  }

  &__shipping {
    --property-value-color: var(--green-color);
  }

  .sf-price {
    --price-regular-font-weight: var(--font-weight--normal);
    --price-regular-font-size: var(--font-size--sm);

    @include for-mobile {
      --price-regular-font-size: var(--font-size--sm);
      --price-regular-font-line-height: 1.4286;
      --price-old-font-size: var(--font-size--sm);
      --price-old-font-line-height: 1.4286;
      --price-special-font-size: var(--font-size--sm);
      --price-special-font-line-height: 1.4286;
    }
    .sf-price__old {
      color: var(--gray-secondary-color);
    }
  }

  &__subtotal-price,
  &__discount-price {
    .sf-price {
      --price-regular-font-weight: var(--font-weight--normal);
    }
  }

  &__total-price {
    --price-font-size: var(--font-size--xl);
    --price-font-weight: var(--font-weight--semibold);
    margin: 0 0 var(--spacer-xs) 0 !important;

    @include for-desktop {
      margin: 0 0 var(--spacer-sm) 0 !important;
    }

    .sf-property__name {
      --property-name-font-weight: var(--font-weight--semibold);
    }

    .sf-price {
      --price-regular-font-weight: var(--font-weight--semibold);
    }
  }

  .product__special-note {
    margin: var(--spacer-sm) 0;
    padding: 0;
  }
}

.empty-cart {
  --heading-description-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-color: var(--c-primary);
  --heading-title-font-weight: var(--font-weight--semibold);
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  height: 100%;

  &__banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  &__heading {
    --heading-title-color: var(--primary-color);
    --heading-title-font-size: var(--h1-font-size);
    --heading-title-font-line-height: var(--global-line-height);
    --heading-title-font-weight: var(--font-weight--normal);

    .sf-heading__title {
      padding: 0;
      margin-bottom: 0;
    }
  }

  &__description {
    --heading-description-font-family: var(--font-family--primary);
    --heading-description-font-size: var(--font-size--normal);
    --heading-description-font-line-height: var(--global-line-height);
    --heading-description-color: var(--black-secondary-color);
  }

  &__image {
    margin: 56px 0;
    max-height: 33vh;
    max-width: 100%;
  }

  @include for-desktop {
    --heading-title-font-size: var(--font-size--xl);
    --heading-title-margin: 0 0 var(--spacer-sm) 0;
  }
}

.collected-product-list {
  flex: 1;

  .sf-collected-product {
    &__aside {
      flex: 0 0 5rem;
    }

    &__image img {
      height: auto;
      aspect-ratio: var(--product-image-ratio);
    }

    &__details {
      position: relative;
    }
  }

  .sf-price {
    --price-regular-font-weight: var(--font-weight--semibold);
  }
}
</style>
